import React from "react";

import Container from "src/components/UI/Grid/Container";
import Grid from "src/components/UI/Grid/Grid";
import Column from "src/components/UI/Grid/Column";
import Text from "src/components/UI/Text";

const PreviewError = (error) => {
  return (
    <Container height="100vh">
      <Grid
        height="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Column textAlign="center">
          <Text as="h2" lineHeight="1" mt="0" mb="5">
            Ett fel uppstod. Testa att ladda om sidan.
          </Text>
        </Column>
      </Grid>
    </Container>
  );
};

export default PreviewError;
