import React from "react";
import { subDays } from "date-fns";
import { useRecoilState } from "recoil";

import { previewDataState } from "src/recoil/atoms";
import PreviewTemplate from "src/components/PageTemplates/PreviewTemplate/PreviewTemplate";
import ErrorTemplate from "src/components/PageTemplates/ErrorTemplate/ErrorTemplate";
import HomeTemplate from "src/components/PageTemplates/PageTemplate/Templates/HomeTemplate";
import ArtistTemplate from "src/components/PageTemplates/ArtistTemplate/ArtistTemplate";
import PostTemplate from "src/components/PageTemplates/PostTemplate/PostTemplate";
import PageTemplate from "src/components/PageTemplates/PageTemplate/PageTemplate";

const BASE_URL = `${process.env.GATSBY_BACKEND_URL}`;

const PreviewPage = (props) => {
  const { location } = props;
  const [previewData] = useRecoilState(previewDataState);

  let pagePreview = {};
  let requests = [];

  let today = subDays(new Date(), 1).toISOString();

  const searchParameters = new URLSearchParams(location.search);
  const pageId = searchParameters.get("p");
  const pageType = searchParameters.get("type");

  switch (pageType) {
    case "page":
      const pageTemplate = searchParameters.get("template");
      switch (pageTemplate) {
        case "page-templates/home.php":
          requests = [
            {
              url: `pages/${pageId}`,
              query: `&_embed`,
            },
            // {
            //   url: `artist`,
            //   query: `&per_page=100&_embed&orderby=title&order=asc`,
            // },
            // {
            //   url: `post`,
            //   query: `&per_page=3&_embed&orderby=date&order=asc`,
            // },
            // {
            //   url: `event`,
            //   query: `&per_page=8&orderby=date&order=asc&after=${today}`,
            // },
          ];
          break;
        case "page-templates/artists.php":
          requests = [
            {
              url: `pages/${pageId}`,
              query: `&_embed`,
            },
            // TODO: PASS RELATED ARTIST production IN filter ARGUMENT
            {
              url: `artist`,
              query: `&per_page=100&_embed&orderby=title&order=asc`,
            },
          ];
          break;
        case "page-templates/events.php":
          requests = [
            {
              url: `pages/${pageId}`,
              query: `&_embed`,
            },
            {
              url: `event`,
              query: `&per_page=100&orderby=date&order=asc&after=${today}`,
            },
          ];
          break;
        case "page-templates/news.php":
          requests = [
            {
              url: `pages/${pageId}`,
              query: `&_embed`,
            },
            {
              url: `posts`,
              query: `&per_page=12&_embed&orderby=date&order=desc`,
            },
          ];
          break;
        case "page-templates/agency.php":
          requests = [
            {
              url: `pages/${pageId}`,
              query: `&_embed`,
            },
            // TODO: PASS RELATED ARTIST division IN filter ARGUMENT
            {
              url: `artist`,
              query: `&per_page=100&_embed&orderby=title&order=asc`,
            },
          ];
          break;
        case "page-templates/about.php":
          requests = [
            {
              url: `pages/${pageId}`,
              query: `&_embed`,
            },
            {
              url: `staff`,
              query: `&per_page=100&_embed&orderby=title&order=asc`,
            },
          ];
          break;
        default:
          requests = [
            {
              url: `pages/${pageId}`,
              query: `&_embed`,
            },
          ];
      }
      // Setup page preview data
      const { page } = previewData;
      pagePreview = page?.length > 0 ? page[0] : null;
      return (
        <PreviewTemplate
          requests={requests}
          previewBar={
            pagePreview
              ? {
                  link: BASE_URL,
                  title: pagePreview.title,
                  id: pagePreview.databaseId
                    ? pagePreview.databaseId
                    : pagePreview.id,
                }
              : null
          }
        >
          {pagePreview && <PageTemplate previewData={pagePreview} {...props} />}
        </PreviewTemplate>
      );
    case "post":
      requests = [
        {
          url: `posts/${pageId}`,
          query: `&_embed`,
        },
        // TODO: PASS RELATED ARTIST id IN include ARGUMENT
        {
          url: `artist`,
          query: `&per_page=100&_embed&orderby=title&order=asc`,
        },
      ];

      // Setup post preview data
      const { post } = previewData;
      pagePreview = post?.length > 0 ? post[0] : null;

      return (
        <PreviewTemplate
          requests={requests}
          previewBar={
            pagePreview
              ? {
                  link: BASE_URL,
                  title: pagePreview.title,
                  id: pagePreview.databaseId,
                }
              : null
          }
        >
          {pagePreview && <PostTemplate previewData={pagePreview} {...props} />}
        </PreviewTemplate>
      );
    case "artist":
      requests = [
        {
          url: `artist/${pageId}`,
          query: `&_embed`,
        },
        {
          url: `staff`,
          query: `&per_page=100&_embed&orderby=title&order=asc`,
        },
        // TODO: PASS RELATED ARTIST id IN include ARGUMENT
        {
          url: `artist`,
          query: `&per_page=100&_embed&orderby=title&order=asc`,
        },
        // TODO: GET EVENTS FOR HIGHLIGHT EVENTS
        // TODO: PASS ARTIST title IN search ARGUMENT
        // {
        //   url: `event`,
        //   query: `&per_page=100&_embed&orderby=date&order=asc&after=${today}`,
        // },
      ];

      // Setup artist preview data
      const { artist } = previewData;
      pagePreview = artist?.length > 0 ? artist[0] : null;

      return (
        <PreviewTemplate
          requests={requests}
          previewBar={
            pagePreview
              ? {
                  link: BASE_URL,
                  title: pagePreview.title,
                  id: pagePreview.databaseId,
                }
              : null
          }
        >
          {pagePreview && (
            <ArtistTemplate previewData={pagePreview} {...props} />
          )}
        </PreviewTemplate>
      );

    default:
      return (
        <ErrorTemplate title="Sidan kunde inte förhandsvisas. Testa att ladda om sidan igen." />
      );
  }
};

export default PreviewPage;
