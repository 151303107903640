import getImageObject from "src/helpers/getImageObject";

export const parseRestArtist = (artist) => {
  const {
    division,
    cta,
    country,
    introduction,
    video,
    release,
    chapters,
    links,
    agent,
    work_areas,
    work_areas_agency,
    related_artists,
    territories,
  } = artist.acf;
  const parsedArtist = {
    databaseId: artist.id,
    id: artist.id,
    title: artist.title.rendered,
    link: artist.link,
    featuredImage:
      artist?._embedded["wp:featuredmedia"]?.length > 0
        ? getImageObject(
            artist._embedded["wp:featuredmedia"][0]?.media_details?.sizes?.full
              .source_url
          )
        : null,
    image:
      artist?._embedded["wp:featuredmedia"]?.length > 0
        ? getImageObject(
            artist._embedded["wp:featuredmedia"][0]?.media_details?.sizes?.full
              .source_url
          )
        : null,
    division: division,
    cta: cta,
    country: country,
    agent: { databaseId: agent.ID },
    territories: territories,
    workAreas: work_areas,
    workAreasAgency: work_areas_agency,
    links: links,
    introduction,
    chapters: chapters,
    video: {
      use: video.use,
      image: video.image,
      service: video.service,
      youtubeId: video.youtube_id,
      vimeoId: video.vimeo_id,
    },
    release: {
      use: release.use,
      title: release.title,
      image: release.image,
      spotifyUri: release.spotify_uri,
    },
    relatedArtists: related_artists,
    acfArtistPage: {
      division: division,
      cta: cta,
      country: country,
      agent: { databaseId: agent.ID },
      territories: territories,
      workAreas: work_areas,
      workAreasAgency: work_areas_agency,
      links: links,
      introduction,
      chapters: chapters,
      video: {
        use: video.use,
        image: video.image,
        service: video.service,
        youtubeId: video.youtube_id,
        vimeoId: video.vimeo_id,
      },
      release: {
        use: release.use,
        title: release.title,
        image: release.image,
        spotifyUri: release.spotify_uri,
      },
      relatedArtists: related_artists,
    },
  };

  return parsedArtist;
};
