import getImageObject from "src/helpers/getImageObject";
import { format } from "date-fns";
import svLocale from "date-fns/locale/sv";

export const parseRestPost = (post) => {
  const { related_artist } = post?.acf;
  const parsedArtist = {
    databaseId: post?.id,
    title: post?.title?.rendered,
    date: post?.date,
    link: post?.link,
    slug: post?.link,
    content: post?.content?.rendered,
    label: format(new Date(post?.date), "dd MMM yyyy", {
      locale: svLocale,
    }),
    featuredImage:
      post?._embedded["wp:featuredmedia"]?.length > 0
        ? getImageObject(
            post._embedded["wp:featuredmedia"][0]?.media_details?.sizes?.full
              .source_url
          )
        : null,
    image:
      post?._embedded["wp:featuredmedia"]?.length > 0
        ? getImageObject(
            post._embedded["wp:featuredmedia"][0]?.media_details?.sizes?.full
              .source_url
          )
        : null,
    acfNewsPage: {
      relatedArtist: {
        databaseId: related_artist?.ID ? related_artist.ID : "",
      },
    },
  };

  return parsedArtist;
};
