import getImageObject from "src/helpers/getImageObject";

export const parseRestPage = (page) => {
  const {
    title,
    sub_title,
    content,
    slide,
    business_areas,
    contacts,
  } = page?.acf;
  const parsedArtist = {
    id: page?.id,
    databaseId: page?.id,
    title: page?.title?.rendered,
    content: page?.content?.rendered,
    featuredImage:
      page?._embedded["wp:featuredmedia"]?.length > 0
        ? getImageObject(
            page._embedded["wp:featuredmedia"][0]?.media_details?.sizes?.full
              .source_url
          )
        : null,
    template: {
      templateName: page?.template,
    },
    acfPage: {
      title: title,
      subTitle: sub_title,
      content: content,
    },
    acfHomePage: {
      title: title,
      slide: slide,
    },
    acfAgencyPage: {
      title: title,
      subTitle: sub_title,
      content: content,
    },
    acfAboutPage: {
      title: title,
      subTitle: sub_title,
      content: content,
      businessAreas:
        business_areas?.length > 0
          ? business_areas.map((item) => {
              return {
                businessArea: item.business_area,
                businessDescription: item.business_description,
              };
            })
          : [],
      contacts:
        contacts?.length > 0
          ? contacts.map(({ contact }) => {
              return {
                contact: {
                  title: contact.post_title,
                  acfStaffPage: {
                    role: contact.acf.role,
                    mail: contact.acf.mail,
                    phoneNumbers: contact.acf.phone_numbers,
                  },
                },
              };
            })
          : [],
    },
  };

  return parsedArtist;
};
