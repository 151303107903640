import React from "react";
import styled from "styled-components";

import Container from "src/components/UI/Grid/Container";
import Grid from "src/components/UI/Grid/Grid";
import Column from "src/components/UI/Grid/Column";
import Label from "src/components/UI/Typography/Label";

const Bar = styled.div`
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.light};

  a {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.light};

    .preview-content {
      ${(props) => props.theme.mixins.textElipsis}
      display: block;
    }

    .bold {
      font-weight: bold;
    }

    .icon {
      margin-left: 16px;
    }
  }
`;

const PreviewBar = ({ link, id, title, ...rest }) => (
  <Bar {...rest}>
    <a
      href={`${link}/wp/wp-admin/post.php?post=${id}&action=edit`}
      target="_self"
    >
      <Container>
        <Grid>
          <Column width={6 / 12}>
            <div className="preview-content">
              <span className="bold">Förhandsvisning</span>
              {title && `: ${title}`}
            </div>
          </Column>
          <Column width={6 / 12} textAlign="right">
            <Label>
              Redigera <span className="icon">→</span>
            </Label>
          </Column>
        </Grid>
      </Container>
    </a>
  </Bar>
);

export default PreviewBar;
