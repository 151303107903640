import getImageObject from "src/helpers/getImageObject";
import { format } from "date-fns";
import svLocale from "date-fns/locale/sv";

export const parseRestEvent = (event) => {
  const { date, location, ticket, artist } = event?.acf;
  const parsedArtist = {
    databaseId: event?.id,
    title: event?.title?.rendered,
    date: event?.date,
    link: event?.link,
    slug: event?.link,
    acfEventPage: {
      artist: { title: artist.title, uri: artist.link },
      date: date,
      location: location,
      ticket: {
        status: ticket.status,
        releaseDate: ticket.release_date,
        link: ticket.link,
      },
    },
  };

  return parsedArtist;
};
