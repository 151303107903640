export const parseRestStaff = (staff) => {
  const { role, mail, phone_numbers } = staff.acf;
  const parsedArtist = {
    id: staff.id,
    databaseId: staff.id,
    name: staff.title.rendered,
    role: role,
    mail: mail,
    phoneNumbers: phone_numbers,
  };

  return parsedArtist;
};
