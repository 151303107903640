import React, { useState, useEffect } from "react";
import axios from "axios";
import url from "url";
import { useRecoilState } from "recoil";

import { previewDataState } from "src/recoil/atoms";
import { parseRestArtist } from "src/parsers/artistParser";
import { parseRestPost } from "src/parsers/postParser";
import { parseRestStaff } from "src/parsers/staffParser";
import { parseRestPage } from "src/parsers/pageParser";
import { parseRestEvent } from "src/parsers/eventParser";
import PreviewLoading from "./PreviewLoading/PreviewLoading";
import PreviewError from "./PreviewError/PreviewError";
import PreviewBar from "./PreviewBar/PreviewBar";

const API_URL = `${process.env.GATSBY_BACKEND_URL}/wp-json/wp/v2`;

const PreviewTemplate = ({ requests, previewBar, children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [previewData, setPreviewData] = useRecoilState(previewDataState);

  const { preview } = previewData;

  useEffect(() => {
    if (!loading && !preview && requests) {
      setLoading(true);

      const promises = requests.map((request) => {
        return axios.get(
          `${API_URL}/${request.url}?${request.query}`,
          request.query.includes("_wpnonce") && { withCredentials: true }
        );
      });

      axios
        .all(promises)
        .then(
          axios.spread((...responses) => {
            const data = responses.map((response) => {
              let postPath = response?.request?.responseURL
                ? url.parse(response.request.responseURL).pathname
                : "";
              let postType = postPath.split("/")[4];
              let postSingle = postPath.split("/")[5];
              let postPlural =
                postType.substring(postType.length - 1) === "s" ? true : false;
              if (!isNaN(postSingle)) {
                if (postPlural) {
                  postType = postType.substring(0, postType.length - 1);
                }
              } else {
                if (!postPlural) {
                  postType += "s";
                }
              }
              const posts =
                Array.isArray(response.data) && response.data.length > 0
                  ? response.data
                  : response.data
                  ? [].concat(response.data)
                  : [];

              const parsedPosts = posts.map((post) => {
                if (postType === "artist" || postType === "artists") {
                  post = parseRestArtist(post);
                }
                if (postType === "post" || postType === "posts") {
                  post = parseRestPost(post);
                }
                if (postType === "staff" || postType === "staffs") {
                  post = parseRestStaff(post);
                }
                if (postType === "page" || postType === "pages") {
                  post = parseRestPage(post);
                }
                if (postType === "event" || postType === "events") {
                  post = parseRestEvent(post);
                }
                return post;
              });

              const data = {
                postType: postType,
                posts: parsedPosts,
              };

              return { ...response, data };
            });

            return data;
          })
        )
        .then((result) => {
          const data = result.filter(
            (result) => result?.status === 200 && result?.data?.postType
          );

          if (data.length > 0) {
            let tempPreviewData = {};
            data.forEach(({ data }) => {
              tempPreviewData[data.postType] = data.posts;

              return data;
            });

            const newPreviewData = {
              ...previewData,
              ...tempPreviewData,
              preview: true,
            };

            setPreviewData(newPreviewData);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          setError(error);
        });
    }
  }, []);

  return (
    (loading && <PreviewLoading />) ||
    (error && <PreviewError error={error} />) || (
      <React.Fragment>
        {previewBar && (
          <PreviewBar
            link={previewBar.link}
            title={previewBar.title}
            id={previewBar.id}
          />
        )}
        {children}
      </React.Fragment>
    )
  );
};

export default PreviewTemplate;
